<template>
    <div>
        <infoHeader :content_name="'AMR异常信息-详情'" style="height: 70px" />
        <div style="float: right; margin-top: -43px">
            <el-button
                type="danger"
                @click="createWorkOrder"
                v-if="errorInfo.abnorma.manual"
                >生成人工工单</el-button
            >
            <el-button type="primary" @click="toErrorWiki">跳转至该异常wiki资料</el-button>
            <el-button @click="reportConfirm">确认异常核实完成</el-button>
        </div>

        <el-divider content-position="left">系统发生的错误记录</el-divider>
        <el-descriptions title="" direction="vertical" :column="5" border>
            <el-descriptions-item label="故障记录系统ID" :span="1">{{
                errorInfo.id
            }}</el-descriptions-item>
            <el-descriptions-item label="故障编号" :span="1">{{
                errorInfo.error_code
            }}</el-descriptions-item>
            <el-descriptions-item label="故障对象" :span="1">{{
                errorInfo.report_obj
            }}</el-descriptions-item>
            <el-descriptions-item label="故障类型">
                <template v-if="errorInfo.report_type === 1">调度异常</template>
                <template v-else-if="errorInfo.report_type === 2"
                    >设备异常</template
                >
                <template v-else>异常</template>
            </el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left" v-if="errorInfo.report_type === 1"
            >错误发生对象</el-divider
        >
        <el-descriptions
            v-if="errorInfo.report_type === 1"
            direction="vertical"
            :column="5"
            border
        >
            <el-descriptions-item label="异常车库id" :span="1">{{
                errorInfo.garage.garage_id
            }}</el-descriptions-item>
            <el-descriptions-item label="异常车库名称" :span="1">{{
                errorInfo.garage.info_name
            }}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions
            v-if="errorInfo.report_type === 2"
            direction="vertical"
            :column="5"
            border
        >
            <el-descriptions-item label="发生错误的车库id" :span="1">{{
                errorInfo.equipment.equipment_id
            }}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">错误码详细信息</el-divider>
        <el-descriptions direction="vertical" :column="3" border>
            <el-descriptions-item label="故障信息" :span="3">{{
                errorInfo.abnorma.error_info
            }}</el-descriptions-item>
            <el-descriptions-item label="错误对应模块">{{
                errorInfo.module.module_name
            }}</el-descriptions-item>
            <el-descriptions-item label="故障等级">{{
                errorInfo.abnorma.error_level
            }}</el-descriptions-item>
            <el-descriptions-item label="是否需要生成工单">
                <template v-if="errorInfo.abnorma.manual">是</template>
                <template v-else>否</template>
            </el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">错误对应的产品</el-divider>
        <el-descriptions direction="vertical" :column="3" border>
            <el-descriptions-item label="故障码所属产品">{{
                errorInfo.product.product_name
            }}</el-descriptions-item>
            <el-descriptions-item label="产品类型">{{
                errorInfo.product.product_type
            }}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import {
    get,
    report_confirm
} from '@/api/manage/operation/error_report_manage/detail.js';
import { work_order_create } from '@/api/manage/operation/work_order/work_order.js';
export default {
    name: 'errorDetail',
    data() {
        return {
            errorID: null,
            errorInfo: {
                id: '1',
                abnorma: {},
                equipment: {},
                error_code: '',
                module: {},
                product: {},
                report_obj: {},
                report_type: {}
            }
        };
    },
    created() {
        this.errorID = this.$route.query.errorID;
        this.getInfo();
    },
    methods: {
        async getInfo() {
            try {
                const res = await get({ id: this.errorID });
                if (res.code === 200) {
                    this.$message.success(res.msg);
                    this.errorInfo = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            } catch (error) {
                this.$message.error('请求失败');
            }
        },
        reportConfirm() {
            console.log('确认异常核实');
            if (this.errorInfo.abnorma.manual) {
                console.log('需要生成人工工单');
                report_confirm({ id: this.errorID }).then(res => {
                    if (res.code == 200) {
                        this.$router.go(0);
                    }
                });
            } else {
                report_confirm({ id: this.errorID }).then(res => {
                    if (res.code == 200) {
                        this.$router.go(-1);
                    }
                });
            }
        },
        createWorkOrder() {
            this.$router.push({ name: 'work_Hall' });
            work_order_create({ fault_id: this.errorID }).then(res => {
                console.log('创建工单');
                if (res.code == 200) {
                    console.log('跳转工单大厅');
                    this.$router.push({ name: 'work_Hall' });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        toErrorWiki(){
            console.log(this.errorInfo.id);
            this.$router.push({ name: 'wiki',query:{
                error_code:this.errorInfo.error_code,
                error_obj_id:this.errorInfo.report_obj
            } });
        }
    }
};
</script>

<style scoped lang="scss"></style>
