import request from '@/utils/request'

// 这个接口是多用接口 可以搜索也可以获取
export function get(data) {
    return request({
        url: '/platform_api/manage_api/error_report/get',
        method: 'post',
        data: data
    })
}

export function report_confirm(data) {
    /**
     * 确认异常
     * @param {int} id - 要确认异常的ID
     */
    return request({
        url: '/platform_api/manage_api/error_report/report_confirm',
        method: 'post',
        data: data
    })
}